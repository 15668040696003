import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";

import { errorBoundary } from "@packages/theme-mui-v5";
import MessageBus from "@packages/message-bus";

import CaseManagementSpaConfig from "./config";
import CaseManagmentSpaRoot from "./CaseManagementSpaRoot";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  errorBoundary,
  rootComponent: CaseManagmentSpaRoot
});

export async function mount(props) {
  MessageBus.publish("app.navbar.title", { title: CaseManagementSpaConfig.displayName });
  MessageBus.publish("app.load", CaseManagementSpaConfig);
  return lifecycles.mount(props);
}

export async function unmount(props) {
  MessageBus.publish("app.unload", {});
  return lifecycles.unmount(props);
}

export const { bootstrap } = lifecycles;
