import { AttachmentFormValues, Box, IconButton } from "@packages/theme-mui-v5";
import { AttachFile, Clear } from "@mui/icons-material";

export type NewAttachmentListProps = {
  attachments: AttachmentFormValues[];
  onRemove: (attachment: AttachmentFormValues, index: number) => void;
};

export function NewAttachmentList({ attachments, onRemove }: NewAttachmentListProps) {
  return (
    <Box display="flex" gap={2}>
      {attachments.map((attachment, index) => {
        return (
          <Box key={index} display="flex" gap={1} alignItems="center">
            <AttachFile /> {attachment.fileName}
            <IconButton onClick={() => onRemove(attachment, index)}>
              <Clear />
            </IconButton>
          </Box>
        );
      })}
    </Box>
  );
}
