import { useCallback, useMemo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  ColDef,
  DataGrid,
  KeyCreatorParams,
  RowAction,
  RowActionsCell,
  useSearchServerSideDataSource,
  ValueFormatterParams
} from "@packages/data-grid";
import { Paper } from "@packages/theme-mui-v5";
import {
  _case,
  getCaseEventCategoriesOptions,
  getCaseImpactCategoriesOptions,
  getCaseInitiatingActionsOptions,
  getCaseProcessFocusCategoriesOptions,
  getCaseSeverityCategoriesOptions,
  getCaseTypeCategoriesOptions,
  postCasesSearchMutation
} from "@packages/case-management-queries";
import { DEFAULT_DATE_FORMAT, getDateInLocalTime } from "../../utils/src";
import { ApiListResponse, PermissionsContext } from "@packages/service-api";

export type CaseManagementDataGridPageProps = {
  permissions: PermissionsContext;
  onEdit?: (caseId: string) => void;
};

export function CaseManagementDataGridPage({
  permissions,
  onEdit
}: CaseManagementDataGridPageProps) {
  const searchCase = useMutation(postCasesSearchMutation());

  const { data: { data: eventCategoriesOptions } = {} } = useQuery(getCaseEventCategoriesOptions());
  const { data: { data: impactCategoriesOptions } = {} } = useQuery(
    getCaseImpactCategoriesOptions()
  );

  const { data: { data: initiatingActionsOptions } = {} } = useQuery(
    getCaseInitiatingActionsOptions()
  );
  const { data: { data: processFocusCategoriesOptions } = {} } = useQuery(
    getCaseProcessFocusCategoriesOptions()
  );
  const { data: { data: severityCategoriesOptions } = {} } = useQuery(
    getCaseSeverityCategoriesOptions()
  );

  const { data: { data: typeCategoriesOptions } = {} } = useQuery(getCaseTypeCategoriesOptions());

  const defaultColDef: ColDef<_case> = useMemo(() => {
    return {
      sortable: true,
      cellDataType: "text"
    };
  }, []);

  const [skipKeywordList, sortConfig] = useMemo(() => {
    return [
      ["createdOn", "alarmDate"],
      {
        caseInitiatingActionId: "caseInitiatingAction.title",
        caseEventCategoryId: "caseEventCategory.title",
        caseImpactCategoryId: "caseImpactCategory.title",
        caseSeverityCategoryId: "caseSeverityCategory.title",
        caseTypeCategoryId: "caseTypeCategory.title"
      }
    ];
  }, []);

  const { setSearchTerm, serverSideDatasource } = useSearchServerSideDataSource<_case>({
    search: async (searchParams) => {
      const response = await searchCase.mutateAsync({ body: searchParams });
      return response as ApiListResponse<_case>;
    },
    skipKeywordList,
    sortConfig
  });

  const comparator = useCallback((a, b) => a?.title?.localeCompare(b?.title), []);

  const columnDefs = useMemo(() => {
    const colDefs: ColDef<_case>[] = [
      {
        field: "caseSitesAssetsTags",
        headerName: "Site",
        autoHeight: true,
        wrapText: true,
        sortable: false,
        valueFormatter: (params) => params?.value?.map((data) => data?.site?.name)?.join("\n")
      },
      {
        field: "caseSitesAssetsTags",
        headerName: "Asset",
        autoHeight: true,
        wrapText: true,
        sortable: false,
        valueFormatter: (params) => params?.value?.map((data) => data?.asset?.assetCode)?.join("\n")
      },
      {
        field: "description",
        headerName: "Description"
      },
      {
        field: "caseProcessFocusCategoriesSelections",
        valueFormatter: (params) => params?.value?.caseProcessFocusCategory?.title || "",
        headerName: "NROC Focus per Plan",
        sortable: false
      },
      {
        field: "caseAlarmId",
        headerName: "Alarm ID"
      },
      {
        field: "alarmDate",
        headerName: "Alarm Time",
        valueFormatter: (params) => getDateInLocalTime(params.value, DEFAULT_DATE_FORMAT)
      },
      {
        field: "caseInitiatingActionId",
        headerName: "Initiating Action",
        menuTabs: ["filterMenuTab"],
        valueGetter: (params) => params.data?.caseInitiatingAction?.title,
        filter: "agSetColumnFilter",
        headerComponentParams: {
          menuIcon: "caseInitiatingAction.id"
        },
        filterParams: {
          buttons: ["apply", "reset"],
          comparator,
          closeOnApply: true,
          values: (params) => params.success(initiatingActionsOptions),
          keyCreator: (params: KeyCreatorParams) => params.value.id,
          valueFormatter: (params: ValueFormatterParams) => params.value.title
        }
      },
      {
        field: "caseEventCategoryId",
        headerName: "Event",
        menuTabs: ["filterMenuTab"],
        valueGetter: (params) => params.data?.caseEventCategory?.title,
        filter: "agSetColumnFilter",
        headerComponentParams: {
          menuIcon: "caseEventCategory.id"
        },
        filterParams: {
          buttons: ["apply", "reset"],
          comparator,
          closeOnApply: true,
          values: (params) => params.success(eventCategoriesOptions),
          keyCreator: (params: KeyCreatorParams) => params.value.id,
          valueFormatter: (params: ValueFormatterParams) => params.value.title
        }
      },
      {
        field: "caseImpactCategoryId",
        headerName: "Impact",
        menuTabs: ["filterMenuTab"],
        valueGetter: (params) => params.data?.caseImpactCategory?.title,
        filter: "agSetColumnFilter",
        headerComponentParams: {
          menuIcon: "caseImpactCategory.id"
        },
        filterParams: {
          buttons: ["apply", "reset"],
          comparator,
          closeOnApply: true,
          values: (params) => params.success(impactCategoriesOptions),
          keyCreator: (params: KeyCreatorParams) => params.value.id,
          valueFormatter: (params: ValueFormatterParams) => params.value.title
        }
      },
      {
        field: "caseSeverityCategoryId",
        headerName: "Severity",
        menuTabs: ["filterMenuTab"],
        valueGetter: (params) => params.data?.caseSeverityCategory?.title,
        filter: "agSetColumnFilter",
        headerComponentParams: {
          menuIcon: "caseSeverityCategory.id"
        },
        filterParams: {
          buttons: ["apply", "reset"],
          comparator,
          closeOnApply: true,
          values: (params) => params.success(severityCategoriesOptions),
          keyCreator: (params: KeyCreatorParams) => params.value.id,
          valueFormatter: (params: ValueFormatterParams) => params.value.title
        }
      },
      {
        field: "caseTypeCategoryId",
        headerName: "Type",
        menuTabs: ["filterMenuTab"],
        valueGetter: (params) => params.data?.caseTypeCategory?.title,
        filter: "agSetColumnFilter",
        headerComponentParams: {
          menuIcon: "caseTypeCategory.id"
        },
        filterParams: {
          buttons: ["apply", "reset"],
          comparator,
          closeOnApply: true,
          values: (params) => params.success(typeCategoriesOptions),
          keyCreator: (params: KeyCreatorParams) => params.value.id,
          valueFormatter: (params: ValueFormatterParams) => params.value.title
        }
      },
      {
        field: "createdBy",
        headerName: "Opened By"
      },
      {
        field: "createdOn",
        headerName: "Opened Date",
        valueFormatter: (params) => getDateInLocalTime(params.value, DEFAULT_DATE_FORMAT)
      },
      {
        field: "caseStage.title",
        headerName: "Status"
      }
    ];

    if (permissions.canUpdate || permissions.canDelete) {
      colDefs.push({
        cellRenderer: (params) => {
          const actions: RowAction[] = [];

          if (permissions.canUpdate) {
            actions.push({
              icon: "edit",
              color: "primary",
              tooltip: "Edit",
              action: () => onEdit(params.data?.id as string)
            });
          }

          return <RowActionsCell actions={actions} />;
        },
        enableCellChangeFlash: false,
        filter: false,
        sortable: false,
        editable: false,
        minWidth: 80,
        maxWidth: 80
      });
    }

    return colDefs;
  }, [
    eventCategoriesOptions,
    impactCategoriesOptions,
    initiatingActionsOptions,
    processFocusCategoriesOptions,
    severityCategoriesOptions,
    typeCategoriesOptions
  ]);

  return (
    <>
      <Paper>
        <DataGrid
          serverSideDatasource={serverSideDatasource}
          onQuickFilterChange={setSearchTerm}
          isServersidePagination
          supressAutoRowHeight={true}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          allowCustomHeader
          csvExportFileName="csv-filename"
          enableCsvExport
          enableQuickSearch
        />
      </Paper>
    </>
  );
}
