import { asset, assetsResponse, postAssetsSearchOptions } from "@packages/accelerator-queries";
import { CommonFieldStateProps } from "./ReactFormFields";
import { useMemo, useState } from "react";
import { Box, useDebounce } from "@packages/theme-mui-v5";
import { AutocompleteBase } from "./MuiAutocompleteBase";
import { useQuery } from "@tanstack/react-query";
import { buildSearchPayload } from "@packages/data-grid";

export type AcceleratorAssetsAutocompleteProps = {
  siteId: string;
  value: asset["id"];
  onChange: (value: asset["id"], asset: asset) => void;
} & CommonFieldStateProps;

export function AcceleratorAssetsAutocomplete({
  siteId,
  value,
  onChange,
  ...autocompleteProps
}: AcceleratorAssetsAutocompleteProps) {
  const [searchInput, setSearchInput] = useState("");
  const searchInputDebounced = useDebounce(searchInput, 200);

  const body = buildSearchPayload({
    searchTerm: searchInputDebounced,
    filter: { siteId }
  }) as Record<string, unknown>;

  const query = useQuery<assetsResponse>(postAssetsSearchOptions({ body }));
  const options = useMemo(() => query.data?.data || [], [query.data]);

  /**
   * Maps the given `value` (assetId) to a matching fetched option.
   */
  const valueMappedOption = useMemo(() => {
    // TODO handling initial value not yet loaded
    return options.find((option) => option.id === value) || null;
  }, [value, options]);

  return (
    <AutocompleteBase
      label="Asset"
      primaryKey="id"
      options={options}
      value={valueMappedOption}
      onChange={onChange}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      {...autocompleteProps}
      ListboxOptionComponent={({ option }) => (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", fontSize: 14 }}>
          <Box component="b" sx={{ mr: 2 }}>
            {/* @ts-expect-error assetCode not on openapi spec */}
            {option?.assetCode}
          </Box>
          <Box sx={{ fontSize: 10 }}>{option?.description}</Box>
        </Box>
      )}
      // @ts-expect-error assetCode not on openapi spec
      getOptionString={(option) => `${option.assetCode} - ${option.description}`}
    />
  );
}
