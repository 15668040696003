import { useMutation, useQuery } from "@tanstack/react-query";
import { CaseManagementForm, CaseManagementFormType } from "./CaseManagementForm";
import {
  caseResponse,
  getCasesByIdOptions,
  putCasesByIdMutation
} from "@packages/case-management-queries";
import { Alert, Box, CircularProgress, useSnackbar } from "@packages/theme-mui-v5";
import { useFileUploadMutation } from "./useFileUploadMutation";

export type CaseManagementEditPageProps = {
  caseId: string;
  onUpdated?: (response: caseResponse) => void;
};

export function CaseManagementEditPage({ caseId, onUpdated }: CaseManagementEditPageProps) {
  const upload = useFileUploadMutation();
  const updateCase = useMutation(putCasesByIdMutation());
  const { enqueueSnackbar } = useSnackbar();

  const caseQuery = useQuery({
    ...getCasesByIdOptions({ path: { id: caseId } }),
    enabled: !!caseId
  });

  if (caseQuery.isLoading) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (caseQuery.isError) {
    return (
      <Box>
        <Alert severity="error">
          Unfortunately, we were unable to load the case. Please try again.
        </Alert>
      </Box>
    );
  } else if (caseQuery.isPending) {
    return null;
  }

  return (
    <>
      <CaseManagementForm
        defaultValues={caseQuery.data.data as CaseManagementFormType} // TODO openapi types do not include relations
        isBusy={updateCase.isPending}
        submitText="Update Case"
        onSubmit={(values, newAttachments) => {
          updateCase.mutate(
            { body: values, path: { id: caseId } },
            {
              onSuccess: async (response) => {
                // TODO handling already uploaded?
                // TODO response type of create case does not include caseFiles relation
                const uploadedCaseFiles = [];
                // const uploadedCaseFiles = (response.data as any)?.caseFiles || [];

                // TODO actual file uploads after successful creation to presigned URLs
                for (const [index, uploadedCaseFile] of uploadedCaseFiles.entries()) {
                  const presignedUrl = uploadedCaseFile.attachmentLocation as string;
                  const newAttachment = newAttachments.at(index);
                  if (newAttachment.attachmentType !== "Link") {
                    await upload.mutateAsync({
                      presignedUrl,
                      file: newAttachment.attachment
                    });
                  }
                }

                enqueueSnackbar("Case updated", { variant: "success" });
                onUpdated?.(response);
              },
              onError: () => {
                enqueueSnackbar("Error updating case", { variant: "error" });
              }
            }
          );
        }}
      />
    </>
  );
}
