import { useDebounce, Box } from "@packages/theme-mui-v5";
import { useState, useMemo } from "react";
import { AutocompleteBase } from "./MuiAutocompleteBase";
import { CommonFieldStateProps } from "./ReactFormFields";
import { postTagsSearchOptions, tag } from "@packages/accelerator-queries";
import { buildSearchPayload } from "@packages/data-grid";
import { useQuery } from "@tanstack/react-query";

export type AcceleratorTagsAutocompleteProps = {
  value: tag["id"];
  onChange: (value: tag["id"], tag: tag) => void;
} & CommonFieldStateProps;

export function AcceleratorTagsAutocomplete({
  value,
  onChange,
  ...autocompleteProps
}: AcceleratorTagsAutocompleteProps) {
  const [searchInput, setSearchInput] = useState("");
  const searchInputDebounced = useDebounce(searchInput, 200);

  const body = buildSearchPayload({ searchTerm: searchInputDebounced }) as Record<string, unknown>;
  const query = useQuery(postTagsSearchOptions({ body }));
  const options = useMemo(() => query.data?.data || [], [query.data]);
  const valueMappedOption = useMemo(() => {
    // TODO handling initial value not yet loaded
    //   const { tag: initialTag, loading: initialTagLoading } = useGetTagById(initialTagId);
    return options.find((option) => option.id === value) || null;
  }, [value, options]);

  return (
    <AutocompleteBase
      label="Tag"
      primaryKey="id"
      options={options}
      value={valueMappedOption}
      onChange={onChange}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      {...autocompleteProps}
      ListboxOptionComponent={({ option }) => (
        <Box data-testid="ListboxOptionComponent" sx={{ wordWrap: "break-word" }}>
          <Box component="span">{option.name}</Box>
        </Box>
      )}
      getOptionString={(option) => option.name}
    />
  );
}
