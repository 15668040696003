import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@packages/theme-mui-v5";

const notificationMatrix = [
  {
    Functionality: "Submit a Case",
    CaseSubmitter: "Yes",
    NROCSMEs: "NA",
    Investigator: "NA",
    Validator: "NA",
    Collaborator: "NA",
    Follower: "NA",
    Subscriber: "Yes",
    ActionPerformedBy: "Yes",
    ActionDirectedAt: "Yes",
    ActionDirectedAtEmailSentTo: "NROC SMEs will be notified if this is an NROC Case"
  },
  {
    Functionality: "Update a Case Details",
    CaseSubmitter: "Yes",
    NROCSMEs: "Yes",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "Yes",
    ActionPerformedBy: "No",
    ActionDirectedAt: "NA",
    ActionDirectedAtEmailSentTo: ""
  },
  {
    Functionality: "Case Stage Change",
    CaseSubmitter: "Yes",
    NROCSMEs: "Yes",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "No",
    ActionPerformedBy: "Yes",
    ActionDirectedAt: "NA",
    ActionDirectedAtEmailSentTo: ""
  },
  {
    Functionality: "Action Item (Add, Edit, Remove)",
    CaseSubmitter: "Yes",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "No",
    ActionPerformedBy: "Yes",
    ActionDirectedAt: "Yes",
    ActionDirectedAtEmailSentTo: 'Email sent to Action Item "Assigned To"'
  },
  {
    Functionality: "Action Item Comment (Add, Edit, Remove)",
    CaseSubmitter: "Yes",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "No",
    ActionPerformedBy: "Yes",
    ActionDirectedAt: "Yes",
    ActionDirectedAtEmailSentTo: 'Email sent to Action Item "Assigned To"'
  },
  {
    Functionality: "Post/Communication  (Add, Edit, Remove)",
    CaseSubmitter: "Yes",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "No",
    ActionPerformedBy: "Yes",
    ActionDirectedAt: "NA",
    ActionDirectedAtEmailSentTo: ""
  },
  {
    Functionality: "Comment on Post/Communcation  (Add, Edit, Remove)",
    CaseSubmitter: "Yes",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "No",
    ActionPerformedBy: "Yes",
    ActionDirectedAt: "Yes",
    ActionDirectedAtEmailSentTo: "Email sent to Creator of Post"
  },
  {
    Functionality: "Detailed Description  (Add, Edit)",
    CaseSubmitter: "Yes",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "No",
    ActionPerformedBy: "Yes",
    ActionDirectedAt: "NA",
    ActionDirectedAtEmailSentTo: ""
  },
  {
    Functionality: "Link/URL  (Add, Edit, Remove)",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "No",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    ActionPerformedBy: "No",
    ActionDirectedAt: "NA",
    ActionDirectedAtEmailSentTo: ""
  },
  {
    Functionality: "File  (Add, Edit, Remove)",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "No",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    ActionPerformedBy: "No",
    ActionDirectedAt: "NA",
    ActionDirectedAtEmailSentTo: ""
  },
  {
    Functionality: "Related Cases  (Add, Edit, Remove)",
    CaseSubmitter: "Yes",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "No",
    ActionPerformedBy: "No",
    ActionDirectedAt: "NA",
    ActionDirectedAtEmailSentTo: ""
  },
  {
    Functionality: "Investigator  (Add, Edit, Remove)",
    CaseSubmitter: "Yes",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "No",
    ActionPerformedBy: "No",
    ActionDirectedAt: "Yes",
    ActionDirectedAtEmailSentTo: "Email sent to newly added Investigator"
  },
  {
    Functionality: "Validator  (Add, Edit, Remove)",
    CaseSubmitter: "Yes",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "No",
    ActionPerformedBy: "No",
    ActionDirectedAt: "Yes",
    ActionDirectedAtEmailSentTo: "Email sent to newly added Validator"
  },
  {
    Functionality: "Collaborator (Add, Edit, Remove)",
    CaseSubmitter: "Yes",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "No",
    ActionPerformedBy: "No",
    ActionDirectedAt: "Yes",
    ActionDirectedAtEmailSentTo: "Email sent to newly added Collaborator"
  },
  {
    Functionality: "Benefit & Value Generation (Add, Edit, Remove)",
    CaseSubmitter: "No",
    NROCSMEs: "Yes",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    ActionPerformedBy: "No",
    ActionDirectedAt: "NA",
    ActionDirectedAtEmailSentTo: ""
  },
  {
    Functionality: "Case Learnings (Add, Edit, Remove)",
    CaseSubmitter: "Yes",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "No",
    ActionPerformedBy: "No",
    ActionDirectedAt: "NA",
    ActionDirectedAtEmailSentTo: ""
  },
  {
    Functionality: "Case Report (Started, Updated)",
    CaseSubmitter: "Yes",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "No",
    ActionPerformedBy: "No",
    ActionDirectedAt: "NA",
    ActionDirectedAtEmailSentTo: ""
  },
  {
    Functionality: "Case Report Distributed",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "No",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    ActionPerformedBy: "No",
    ActionDirectedAt: "Yes",
    ActionDirectedAtEmailSentTo: "Recipients selected"
  }
];

const permissionsMatrix = [
  {
    Functionality: "Submit a Case",
    CaseSubmitter: "Yes",
    NROCSMEs: "Yes",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "Yes",
    Viewer: "Yes",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Update a Case Details",
    CaseSubmitter: "Yes",
    NROCSMEs: "Yes",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Case Stage Change",
    CaseSubmitter: "No",
    NROCSMEs: "Yes",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "NA",
    Administrator: "Yes"
  },
  {
    Functionality: "Action Item (Add)",
    CaseSubmitter: "Yes",
    NROCSMEs: "Yes",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Action Item (Edit, Remove)",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Action Item Comment (Add)",
    CaseSubmitter: "Yes",
    NROCSMEs: "Yes",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Action Item Comment (Edit, Remove)",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Post/Communication (Add)",
    CaseSubmitter: "Yes",
    NROCSMEs: "Yes",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "Yes",
    Viewer: "Yes",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Post/Communication (Edit, Remove)",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Comment on Post/Communcation (Add)",
    CaseSubmitter: "Yes",
    NROCSMEs: "Yes",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "Yes",
    Viewer: "Yes",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Comment on Post/Communcation (Edit, Remove)",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Detailed Description  (Add, Edit)",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "Yes",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Link/URL  (Add)",
    CaseSubmitter: "Yes",
    NROCSMEs: "Yes",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "Yes",
    Viewer: "Yes",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Link/URL  (Edit, Remove)",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "File  (Add)",
    CaseSubmitter: "Yes",
    NROCSMEs: "Yes",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "Yes",
    Viewer: "Yes",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "File  (Edit, Remove)",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Related Cases  (Add, Edit, Remove)",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Investigator  (Add, Edit, Remove)",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Validator  (Add, Edit, Remove)",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Collaborator (Add, Edit, Remove)",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Benefit & Value Generation Added (Add, Edit, Remove)",
    CaseSubmitter: "No",
    NROCSMEs: "Yes",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Case Learnings (Add)",
    CaseSubmitter: "Yes",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "Yes",
    Collaborator: "Yes",
    Follower: "Yes",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Case Learnings (Edit, Remove)",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Case Report (Started, Updated)",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "Yes",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  },
  {
    Functionality: "Case Report Distributed",
    CaseSubmitter: "No",
    NROCSMEs: "No",
    Investigator: "Yes",
    Validator: "No",
    Collaborator: "No",
    Follower: "No",
    Subscriber: "No",
    Viewer: "No",
    SubmitterOfSectionDetail: "Yes",
    Administrator: "Yes"
  }
];

export function CaseManagementHelpPage() {
  const displayConditionColor = (value) => {
    if (value === "Yes") {
      return <Typography sx={{ color: "#4cceac" }}>{value}</Typography>;
    }
    if (value === "No") {
      return <Typography sx={{ color: "#dc143c" }}>{value}</Typography>;
    }
    if (value === "NA") {
      return <Typography sx={{ color: "orange" }}>{value}</Typography>;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Card>
        <CardHeader title="What is Case Management?" />

        <CardContent>
          Case Management can be described as the information management, workflow practices,
          procedures and requirements used to manage cases.
          <br />
          In NROC, a case is described as an investigation into abnormal behavior of an equipment or
          process on a plant.
          <br />
          The NROC Case Management Application is used for the following:
          <ul>
            <li>
              To conduct research, manage communication, collaboration, data mining, storage and
              record archiving.{" "}
            </li>
            <li>Help investigators use, manage, consolidate, share, and protect information. </li>
            <li>
              Provide the ability to track and shape the business processes and workflows to move
              the investigation forward.{" "}
            </li>
            <li>
              Automate the financial management aspect of the case, including additional tonnes
              produced, human-hours saved etc.{" "}
            </li>
            <li>Allow for collaboration and sharing of information with other employees. </li>
          </ul>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title="Why Case Management?" />

        <CardContent>
          <ul>
            <li>
              <b>Identification of Issues</b> - Identification of reliability issues through various
              means such as routine inspections, predictive maintenance techniques, monitoring
              systems, or reports of malfunctions from operators.
            </li>
            <li>
              <b>Assessment and Prioritization</b> - Once issues are identified, they are assessed
              to determine their severity, impact on operations, and potential consequences. This
              assessment helps prioritize which issues need immediate attention based on factors
              such as safety risks, production downtime, or financial implications.
            </li>
            <li>
              <b>Planning and Action</b> - A case management plan is developed to address each
              identified reliability issue. This plan includes defining the root cause of the
              problem, determining appropriate corrective actions or solutions, establishing
              timelines for implementation, allocating resources, and assigning responsibilities to
              relevant personnel.
            </li>
            <li>
              <b>Coordination and Communication</b> - Effective case management involves
              coordinating efforts among various departments or teams involved in resolving
              reliability issues, such as maintenance, engineering, operations, and management.
              Clear communication channels are established to ensure everyone is informed about the
              status of each case, progress made, and any challenges encountered.
            </li>
            <li>
              <b>Implementation and Monitoring</b> - The planned actions to address reliability
              issues are implemented according to the established timelines and recommendations.
              Progress is monitored closely to ensure that the actions taken are effective in
              resolving the issues and preventing recurrence. Adjustments may be made to the plan as
              needed based on feedback and ongoing assessments.
            </li>
            <li>
              <b>Documentation and Evaluation</b> - Throughout the case management process,
              documentation is maintained to record all relevant information, including the details
              of the reliability issues, actions taken, outcomes achieved, and lessons learned.
              Periodic evaluations are conducted to assess the overall effectiveness of the case
              management process and identify opportunities for improvement.
            </li>
          </ul>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title="Case Management Stages" />

        <CardContent>
          <ol>
            <li>
              <b>Submitted</b> - When a case is now entered via an AVEVA alarm or the Web Form
            </li>
            <li>
              <b>Assigned</b> - At least one Investigator has been assigned
            </li>
            <li>
              <b>
                Validate <span style={{ color: "red" }}>*</span>
              </b>{" "}
              - At least one Validator has been assigned and the case is pending confirmation to
              proceed with an Investigation
            </li>
            <li>
              <b>Investigate</b> - The Investigator(s) on the case can start uploading files, URLs,
              adding communication, comments, action items, contributing to benefit and value
              generation as well as report generation etc
            </li>
            <li>
              <b>
                Collaborate <span style={{ color: "red" }}>*</span>
              </b>{" "}
              - During this optional stage, the Investigator can add persons from the ETC, other
              Groups or Sites to assist with quickly resolving the case. Collaborators can start
              uploading files, URLs, adding communication, comments, action items etc
            </li>
            <li>
              <b>
                Report Generation <span style={{ color: "red" }}>*</span>
              </b>{" "}
              - Utilizing all or some of the information submitted for the case, a report is
              generated and sent out via email to relevant persons.
            </li>
            <li>
              <b>
                Closed <span style={{ color: "red" }}>*</span>
              </b>{" "}
              - A report was generated and the case was resolved.
            </li>
            <li>
              <b>
                Unresolved <span style={{ color: "red" }}>*</span>
              </b>{" "}
              - The case was not resolved and a report may or may not have been sent out.
            </li>
            <li>
              <b>
                Escalated <span style={{ color: "red" }}>*</span>
              </b>{" "}
              - The case is sent to a Supervisor/Manager for validation due to no validation by
              selected Validators.
            </li>
          </ol>
          <Typography>
            Stages with red asterisk (<span style={{ color: "red" }}>*</span>) above are manually
            progressed to by the Investigator. All other stages are automatically progressed to
            based on some user action.
          </Typography>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title="Case Management Process" />

        <CardContent>
          <ol>
            <li>A case is submitted via the form within CMA or via AVEVA. (Stage – Submitted)</li>
            <li>
              Users from the SMEs group in the application are notified via email if the case is an
              NROC case.
            </li>
            <li>
              If this is an NROC case, an Investigator is added to the case by a SME EITHER when the
              SME clicks “Assign to Me” OR navigates to the Investigators section of the case and
              assigns another user as the Investigator.
            </li>
            <li>
              The case is then automatically progressed to the Assigned Stage once an Investigator
              is added (Stage - Assigned)
            </li>
            <li>
              If this is not an NROC case, the SMEs are not notified, the submitter is assigned as
              the Investigator and the case is automatically progressed from the Submitted stage to
              the Assigned stage.
            </li>
            <li>
              Email notifications are sent out to indicating an investigator has been assigned to
              the case and the case is not in the Assigned Stage.
            </li>
            <li>
              The case can be progressed to the Validate Stage EITHER after a validator is added OR
              if the Investigator selects the option to “Change Stage” to move the case to the
              Validate Stage
            </li>
            <li>
              To manually move a case to the Validate Stage, the following prerequisites must be
              met:
              <ul>
                <li>The Investigator adds at least one Validator </li>
                <li>The Investigator adds a message to the Validators</li>
              </ul>
            </li>
            <li>The case is then moved to the Validate Stage (Stage - Validate).</li>
            <li>Email notifications are sent out.</li>
            <li>
              To validate the case to start the Investigation, at least one Validator must select
              “Yes” for Investigate, choose “Yes” or “No” for Validate and submit a comment. The
              Validator also has the option to upload a File and URL.{" "}
            </li>
            <li>
              Once a case has one validator providing “Yes” for Investigate, the case automatically
              progresses to the Investigate stage. (Stage – Investigate)
            </li>
            <li>Email notifications are sent out.</li>
            <li>
              If no validator validates the case, it can be progressed to the Escalated Stage and an
              Escalated Validator added to the case.
            </li>
            <li>
              During the Investigate stage, files can be added, URLs added, action items assigned,
              posts and comments on posts can be made, benefit and value generation information can
              be added etc.
            </li>
            <li>
              To move a case to the Collaborate stage, the Investigator must add Collaborators.
            </li>
            <li>
              Once a collaborator is added, the case is automatically moved to the collaborate stage
              with a system generated message. (Stage – Collaborate)
            </li>
            <li>
              During the Collaboration stage, the same activities performed during the investigation
              stage can be performed here but those permissions are extended to the collaborators
              who have been added.
            </li>
            <li>
              The case is progressed to the Report Generation stage when the report is ready to be
              distributed to the relevant persons via email (Email Distribution List).
            </li>
            <li>
              Once the case is in the Report Generation stage, action items can no longer be added
              but communication, attachments and URLs can be added by case personnel.
            </li>
            <li>The Investigator can leave a case as unresolved from any stage.</li>
          </ol>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title="Case Sections" />

        <CardContent>
          <ol>
            <li>
              <b>Case Details</b> - Contains the details entered on the form and gives a snapshot of
              what the case is about including site, asset, tag, brief description etc.
            </li>
            <li>
              <b>Detailed Description</b> - This entered by the Investigator(s) on the case and is
              used in the report generated at the end of the Investigation.
            </li>
            <li>
              <b>Links & Attachments</b> - All files and URLs entered in various parts of the case
              (such as Communication, Action Items etc) are summarized in this section
            </li>
            <li>
              <b>Asset Notes</b> - Notes made on assets by the SMEs from Accelerator are pulled in
              and optionally added to the case (similar to related cases) to assist with
              troubleshooting
            </li>
            <li>
              <b>Communication & Feedback (Posts)</b> - Similar to social media, posts and comments
              allow for communication and collaboration by case personnel (Submitter, Investigator,
              Validator, Collaborator) and non-case personnel.
            </li>
            <li>
              <b>Action Items</b> - Work to be performed by case personnel with due date, progress,
              comments, attachments, links etc. Action Items can only be added by case personnel.
            </li>
            <li>
              <b>Related Cases</b> - Previous cases that may be related the current case based on
              Site, Asset, Equipment Type/Subtype, Subarea etc pulled in and optionally mapped to
              the case to assist with the investigation.
            </li>
            <li>
              <b>Investigators, Validators and Collaborators</b> - Investigators have the most
              privilege on a case and are responsible for adding validators, adding collaborators
              and generating the report. Validators are responsible ensuring the data on a case is
              accurate and for providing the “go-ahead” for an investigation to begin. Collaborators
              assist with resolving the issue by uploading files, URLs, making posts, commenting on
              posts etc.
            </li>
            <li>
              <b>Benefit & Value Generation</b> - This section is completed by the Investigator and
              highlights information on the potential savings or value to the business by the
              recommended resolution from the investigation.
            </li>
            <li>
              <b>Learnings</b> - This section allows case personnel as well as personnel from the
              site responsible for implementing the case report recommendations, to provide feedback
              on what was resolving the issue at on the plant.
            </li>
            <li>
              <b>History</b> - This section provides details on the progression of the case through
              the various stages.
            </li>
          </ol>
        </CardContent>
      </Card>

      <Card>
        <CardHeader
          title="Email Notification Matrix"
          subheader="The notifications for the individual case sections/functionalities can be turned off/on from your user profile"
        ></CardHeader>
        <div style={{ backgroundColor: "primary[400]" }}></div>
        <CardContent>
          <Grid item xs={12}>
            {!notificationMatrix || !Array.isArray(notificationMatrix) ? (
              <p>No data available</p>
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>FUNCTIONALITY</TableCell>
                      <TableCell>CASE SUBMITTER</TableCell>
                      <TableCell>INVESTIGATORS</TableCell>
                      <TableCell>VALIDATORS</TableCell>
                      <TableCell>COLLABORATORS</TableCell>
                      <TableCell>FOLLOWERS</TableCell>
                      <TableCell>SUBSCRIBERS</TableCell>
                      <TableCell>ACTION PERFORMED BY</TableCell>
                      <TableCell>ACTION DIRECTED AT</TableCell>
                      <TableCell>ACTION DIRECTED AT (EMAIL SENT TO)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {notificationMatrix.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.Functionality}</TableCell>
                        <TableCell>{displayConditionColor(item.CaseSubmitter)}</TableCell>
                        <TableCell>{displayConditionColor(item.Investigator)}</TableCell>
                        <TableCell>{displayConditionColor(item.Validator)}</TableCell>
                        <TableCell>{displayConditionColor(item.Collaborator)}</TableCell>
                        <TableCell>{displayConditionColor(item.Follower)}</TableCell>
                        <TableCell>{displayConditionColor(item.Subscriber)}</TableCell>
                        <TableCell>{displayConditionColor(item.ActionPerformedBy)}</TableCell>
                        <TableCell>{displayConditionColor(item.ActionDirectedAt)}</TableCell>
                        <TableCell>{item.ActionDirectedAtEmailSentTo}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title="Permissions Matrix" />

        <CardContent>
          <Grid item xs={12}>
            {!notificationMatrix || !Array.isArray(notificationMatrix) ? (
              <p>No data available</p>
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>FUNCTIONALITY</TableCell>
                      <TableCell>CASE SUBMITTER</TableCell>
                      <TableCell>NROC SME</TableCell>
                      <TableCell>INVESTIGATORS</TableCell>
                      <TableCell>VALIDATORS</TableCell>
                      <TableCell>COLLABORATORS</TableCell>
                      <TableCell>FOLLOWERS</TableCell>
                      <TableCell>SUBSCRIBERS</TableCell>
                      <TableCell>ACTION PERFORMED BY</TableCell>
                      <TableCell>ACTION DIRECTED AT</TableCell>
                      <TableCell>ACTION DIRECTED AT (EMAIL SENT TO)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {permissionsMatrix.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.Functionality}</TableCell>
                        <TableCell>{displayConditionColor(item.CaseSubmitter)}</TableCell>
                        <TableCell>{displayConditionColor(item.NROCSMEs)}</TableCell>
                        <TableCell>{displayConditionColor(item.Investigator)}</TableCell>
                        <TableCell>{displayConditionColor(item.Validator)}</TableCell>
                        <TableCell>{displayConditionColor(item.Collaborator)}</TableCell>
                        <TableCell>{displayConditionColor(item.Follower)}</TableCell>
                        <TableCell>{displayConditionColor(item.Subscriber)}</TableCell>
                        <TableCell>{displayConditionColor(item.Viewer)}</TableCell>
                        <TableCell>
                          {displayConditionColor(item.SubmitterOfSectionDetail)}
                        </TableCell>
                        <TableCell>{displayConditionColor(item.Administrator)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
