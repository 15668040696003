import {
  CircularProgress,
  MenuItem,
  TextFieldRaw as TextField,
  TextFieldRawProps as TextFieldProps
} from "@packages/theme-mui-v5";
import { getCaseSeverityCategoriesOptions } from "@packages/case-management-queries";
import { useQuery } from "@tanstack/react-query";

export type CaseSeverityCategorySelectFieldProps = Pick<
  TextFieldProps,
  "onBlur" | "value" | "error" | "helperText" | "required" | "sx"
> & {
  onChange?: (caseSeverityCategoryId: string) => void;
};

export function CaseSeverityCategorySelectField({
  onChange,
  ...textFieldProps
}: CaseSeverityCategorySelectFieldProps) {
  const query = useQuery(getCaseSeverityCategoriesOptions());

  return (
    <TextField
      select
      size="small"
      variant="standard"
      margin="normal"
      label={
        <>
          Severity Category
          {query.isLoading && <CircularProgress sx={{ ml: 2 }} size={12} />}
        </>
      }
      InputLabelProps={{ shrink: true }}
      // SelectProps={{ MenuProps: { sx: { maxWidth: 320 } } }}
      {...textFieldProps}
      onChange={onChange ? (e) => onChange(e.target.value) : undefined}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>

      {query.data?.data.map((value) => {
        return (
          <MenuItem key={value.id} value={value.id}>
            {value.title}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
