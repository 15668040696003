import CaseManagementSpaProviders from "./CaseManagementSpaProviders";
import CaseManagementSpaRouter from "./CaseManagementSpaRouter";

export default function CaseManagmentSpaRoot() {
  return (
    <CaseManagementSpaProviders>
      <CaseManagementSpaRouter />
    </CaseManagementSpaProviders>
  );
}
