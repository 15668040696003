import type { Client } from "@hey-api/client-fetch";
import { Auth, Amplify } from "aws-amplify";
import { config } from "@nutrien-operations/config";

Amplify.configure(config.Amplify);

/**
 * Wires up the given client to use the current session's access token for requests, if available.
 */
export function setupClientAuth(client: Client) {
  // before every request, get the access token if available
  // Auth.currentSession() will also handle refreshing the token internally if needed
  client.interceptors.request.use(async (request) => {
    try {
      const session = await Auth.currentSession();

      if (session.isValid) {
        const accessToken = session.getAccessToken().getJwtToken();
        request.headers.set("Authorization", `Bearer ${accessToken}`);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Client Auth Error: Unable to get current session.", err);
    }

    return request;
  });
}
