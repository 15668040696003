import { client } from "@packages/case-management-queries";
import { useMutation } from "@tanstack/react-query";

export type PresignedUploadFile = {
  presignedUrl: string;
  file: File;
};

export const fileToBlob = async (file) =>
  new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });

export function useFileUploadMutation() {
  return useMutation({
    mutationFn: async ({ presignedUrl, file }: PresignedUploadFile) => {
      const body = await fileToBlob(file);

      // TODO fix file upload, perhaps need multipart / FormData
      // see `file-upload.ts` example
      const response = await client.post({
        baseUrl: "",
        url: presignedUrl,
        body,
        headers: { "Content-Type": file.type ?? "application/octet-stream" }
      });

      return response;
    }
  });
}
