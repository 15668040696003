import { useMutation } from "@tanstack/react-query";
import { CaseManagementForm } from "./CaseManagementForm";
import { caseResponse, postCasesMutation } from "@packages/case-management-queries";
import { useSnackbar } from "@packages/theme-mui-v5";
import { useFileUploadMutation } from "./useFileUploadMutation";

export type CaseManagementAddPageProps = {
  onAdded?: (response: caseResponse) => void;
};

export function CaseManagementAddPage({ onAdded }: CaseManagementAddPageProps) {
  const upload = useFileUploadMutation();
  const createCase = useMutation(postCasesMutation());
  const { enqueueSnackbar } = useSnackbar();

  return (
    <CaseManagementForm
      isBusy={createCase.isPending}
      submitText="Create Case"
      onSubmit={(values, newAttachments) => {
        createCase.mutate(
          { body: values },
          {
            onSuccess: async (response) => {
              // TODO response type of create case does not include caseFiles relation
              // TODO FIX ME
              const uploadedCaseFiles = [];
              // const uploadedCaseFiles = (response.data as any)?.caseFiles || [];

              // TODO actual file uploads after successful creation to presigned URLs
              for (const [index, uploadedCaseFile] of uploadedCaseFiles.entries()) {
                const presignedUrl = uploadedCaseFile.attachmentLocation as string;
                const newAttachment = newAttachments.at(index);
                if (newAttachment.attachmentType !== "Link") {
                  await upload.mutateAsync({
                    presignedUrl,
                    file: newAttachment.attachment
                  });
                }
              }

              enqueueSnackbar("Case created", { variant: "success" });
              onAdded?.(response);
            },
            onError: () => {
              enqueueSnackbar("Error creating case", { variant: "error" });
            }
          }
        );
      }}
    />
  );
}
