import {
  TextFieldRaw as TextField,
  TextFieldRawProps as TextFieldProps,
  MenuItem,
  CircularProgress
} from "@packages/theme-mui-v5";
import { useQuery } from "@tanstack/react-query";
import { getSitesOptions } from "@packages/accelerator-queries";

export type AcceleratorSelectSiteProps = Pick<
  TextFieldProps,
  "onBlur" | "value" | "error" | "helperText" | "required"
> & {
  onChange: (siteId: string) => void;
};

export function AcceleratorSelectSite({ onChange, ...textFieldProps }: AcceleratorSelectSiteProps) {
  const query = useQuery(getSitesOptions());

  return (
    <TextField
      select
      size="small"
      variant="standard"
      sx={{ mb: 0 }}
      margin="none"
      InputLabelProps={{ shrink: true }}
      label={
        <>
          Site
          {query.isLoading && <CircularProgress sx={{ ml: 2 }} size={12} />}
        </>
      }
      {...textFieldProps}
      onChange={(e) => onChange(e.target.value)}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>

      {query.data?.data?.map((site, index) => (
        <MenuItem key={index} value={site.id}>
          {site.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
