/**
 * Currently, some autocompletes such as `SearchAssets` are defined within `apps/accelerator/*`,
 * but our packages should only be importing from other packages, not apps.
 *
 * These should eventually be moved to a reusable library packages such as
 * `@packages/mui-smart-fields` or something like this, so other packages / apps can import it.
 *
 * The new future package would be for "smart components" that integrate with APIs within.
 * Similar to "molecular" components in atomic design.
 *
 * The core theme package `@packages/theme-mui-v5` should only be core / dumb components with styling.
 * Similar to "atomic" components in atomic design.
 */

import * as React from "react";

import { Autocomplete, CircularProgress, TextFieldRaw as TextField } from "@packages/theme-mui-v5";

import { CommonFieldStateProps } from "./ReactFormFields";

export type AutocompleteBaseProps<Value extends object> = CommonFieldStateProps & {
  label: string;
  // helperText: string;
  // error: boolean;
  // required?: boolean;

  /** Used to map value to options.  This value must exist on all of your options. */
  primaryKey: keyof Value;

  searchInput: string;
  setSearchInput: (value: string) => void;

  onChange: (value: string, option: Value) => void;

  loading?: boolean;

  value: Value;
  options: Array<Value>;

  /**
   * How the option will be rendered in the listbox dropdown.
   */
  ListboxOptionComponent: React.FunctionComponent<{ option: Value }>;

  /**
   * How the selected option will be rendered in the input field.
   */
  getOptionString: (option: Value) => string;
};

/**
 * This function attempts to reduce boilerplate for creating an autocomplete component.
 */
export function AutocompleteBase<Value extends object>({
  value,
  options,
  label,
  helperText,
  error,
  searchInput,
  setSearchInput,
  primaryKey,
  loading = false,
  onChange,
  ListboxOptionComponent,
  getOptionString,
  required
}: AutocompleteBaseProps<Value>) {
  return (
    <Autocomplete
      value={value || null} // null is fallback required to mute controlled/uncontrolled value warning
      options={options}
      inputValue={searchInput}
      onInputChange={(event, value) => setSearchInput(value)}
      loading={loading}
      // common ux & styling
      clearOnBlur={false}
      noOptionsText="No result found."
      size="small"
      ListboxProps={{ style: { maxHeight: "60vh" } }}
      filterOptions={(options) => options} // don't do client-side filtering
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            sx={{ mb: 0, minWidth: 100 }}
            label={label}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            helperText={helperText}
            error={!!error}
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        );
      }}
      onChange={(event, value) => {
        if (value) {
          onChange(value[primaryKey] as string, value);
        } else {
          onChange("", null);
        }
      }}
      isOptionEqualToValue={(option, value) => option[primaryKey] === value[primaryKey]}
      renderOption={(props, option) => (
        <li {...props} key={option[primaryKey] as string}>
          <ListboxOptionComponent option={option} />
        </li>
      )}
      getOptionLabel={(option) => {
        return option ? getOptionString(option) : "";
      }}
    />
  );
}
